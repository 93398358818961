import React from 'react';
import './Stake.css';

function Stake() {
  return (
    <div className="stake-page">
      <h1 className="stake-title">Stake Your Tacos</h1>
      <div className="stake-content">
        <p>Our staking platform is coming soon! You'll be able to stake your Taco-Truck tokens and earn rewards. Stay tuned for updates.</p>
      </div>
    </div>
  );
}

export default Stake;
