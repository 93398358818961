import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-page">
      <h1 className="about-title">About Taco-Truck</h1>
      <div className="about-content">
        <p>Taco-Truck is a fun and energetic meme token project built on the Solana blockchain. Our mission is to create a vibrant community where users can enjoy staking, trading, and participating in governance, all while having fun.</p>
        <p>With a total supply of 1 billion tokens and a 1% transaction tax for project funding, Taco-Truck aims to create a sustainable and engaging DeFi ecosystem. Our roadmap includes exciting features like staking, community voting, and more!</p>
        <p>Join us on our journey and become a part of the Taco-Truck community!</p>
      </div>
    </div>
  );
}

export default AboutUs;
